import { Callable } from '@ankor-io/common/lang/functional.types'
import { throttle } from '@ankor-io/common/lang/throttle'
import { WebSocketEvent } from '@ankor-io/common/lang/websocket'

import { Message, SavedStatus } from '../types'
import { DiffSync } from './diff.sync'
import { Document as _Document } from './document'
import { Edit, EditInit } from './edit'
import { EditStack } from './edit.stack'

export class DiffSyncClientImpl implements DiffSyncClient<Object> {
  // open a websocket connection
  // create the document client
  // initialize diff sync
  websocket: WebSocket
  // the client source of truth
  private document: _Document<Object>
  // the diff sync
  private diffSync: DiffSync<Object>
  private observers: Callable[]
  private destructor: Callable
  private url: string
  private onAck: Callable
  private savedStatus: SavedStatus
  private _onForbidden: Callable
  private getToken: () => Promise<string | undefined>

  constructor(options: DiffSyncClientOptions) {
    // create a websocket connection and use the sec-websocket-protocol header to pass
    // the authentication token (we can work with that for authentication)
    this.url = `${options.url}`
    this.getToken = options.getToken || (() => Promise.resolve(options.token))
    this.websocket = new WebSocket(`${this.url}?auth_token=${options.token}`)
    this.document = new _Document(options.documentContent)
    this.diffSync = new DiffSync<Object>(this.document.getContent(), '')
    this.websocket.addEventListener(WebSocketEvent.MESSAGE, this.onMessage.bind(this))
    this.websocket.addEventListener(WebSocketEvent.CLOSE, this.onClose.bind(this))
    this.websocket.addEventListener(WebSocketEvent.ERROR, this.onError.bind(this))
    this.observers = options.updateRunnable ? [options.updateRunnable] : []
    this.destructor = options.destructor || (() => {})
    this.onAck = () => {}
    this.savedStatus = SavedStatus.IS_SAVED
    this._onForbidden = () => {}
  }

  getSavedStatus(): SavedStatus {
    return this.savedStatus
  }

  setUnsaved(): void {
    this.savedStatus = SavedStatus.UNSAVED
  }

  setOnAck(onAck: Callable): void {
    this.onAck = onAck
  }

  getOnAck(): Callable {
    return this.onAck
  }

  close(): void {
    this.websocket.close(1000, 'Client closing the connection')
  }

  registerObserver(update: Callable): void {
    this.observers.push(update)
  }

  unRegisterObserver(update: Callable): void {
    this.observers = this.observers.filter((observer: Callable) => observer !== update)

    if (this.observers.length === 0) {
      // alright, since nobody is observing me anymore I am going to close the websocket connection
      this.close()
      // then I am going to call any destroy behaviour
      this.destructor()
    }
  }

  getContent(): Object {
    return this.document.getContent()
  }

  getShadowContent(): Object {
    return this.diffSync.getShadowContent()
  }

  getBackupContent(): Object {
    return this.diffSync.getBackupContent()
  }

  getShadowLocalRev(): number {
    return this.diffSync.getShadowLocalRev()
  }

  getShadowRemoteRev(): number {
    return this.diffSync.getShadowRemoteRev()
  }

  getBackupLocalRev(): number {
    return this.diffSync.getBackupLocalRev()
  }

  getEditStack(): EditStack {
    return this.diffSync.getEditStack()
  }

  public onChanges(content: Object): void {
    // set the document content
    this.document.setContent(content)
    // perform a document change
    const editStack: EditStack = this.diffSync.documentChange(this.document)

    // send the edit stack on the websocket
    this.savedStatus = !editStack.edits.some((edit) => {
      return edit.getOperations().length
    })
      ? SavedStatus.IS_SAVED
      : SavedStatus.SAVING

    try {
      this.websocket.send(
        JSON.stringify({
          editStack,
          ref: this.diffSync.getRef(),
          isSaved: this.savedStatus,
        } as Message),
      )
    } catch (error) {
      // the changes the user is trying to send are going to get lost but they are going to be small
      // improve this so that we can retry sending the changes. Requires resetting the revisions on the editStack
      this.reconnect()
    }
  }

  public onForbidden(callable: Callable): void {
    this._onForbidden = callable
  }

  private reconnect(): void {
    const _this = this
    this.getToken().then((token) => {
      _this.websocket = new WebSocket(`${this.url}?auth_token=${token}`)
      // restart diff sync with the current content
      _this.diffSync = new DiffSync<Object>(_this.document.getContent(), '')
      _this.websocket.addEventListener(WebSocketEvent.MESSAGE, _this.onMessage.bind(_this))
      _this.websocket.addEventListener(WebSocketEvent.CLOSE, _this.onClose.bind(_this))
      _this.websocket.addEventListener(WebSocketEvent.ERROR, _this.onError.bind(_this))
    })
  }

  public onError(event: Event): void {
    console.debug('>>> ERROR event received on the websocket needs restarting', event)
    // I can be closing either because i don't have more observers and therefore can retire for now
    // or because the server sent a socket shutdown for some reason
    // if it is the latter, try and reconnect
    if (this.observers.length > 0) {
      // for as long as at least one component is observing me, I need to still be alive
      // let me try to reconnect
      throttle(this.reconnect, 1000)
      console.debug('>>> OnError: websocket restarted with listener bound')
    }
  }

  public onClose(event: CloseEvent): void {
    console.debug('>>> CLOSE event received on the websocket needs restarting', event)

    if (event.code === 4403) {
      this._onForbidden()
      return
    }

    // I can be closing either because i don't have more observers and therefore can retire for now
    // or because the server sent a socket shutdown for some reason
    // if it is the latter, try and reconnect
    if (this.observers.length > 0) {
      // for as long as at least one component is observing me, I need to still be alive
      // let me try to reconnect
      this.reconnect()
      console.debug('>>> websocket restarted with listener bound')
    }
  }

  public onMessage(event: MessageEvent): void {
    const { content, ref, isSaved } = JSON.parse(event.data as string)
    console.debug('>>> MESSAGE event received on the websocket', content !== null, ref)
    this.savedStatus = isSaved

    // content is only available in data as part of the initial handshake
    if (content) {
      console.debug('>>> initializing diff sync with content and ref', ref)

      this.savedStatus = SavedStatus.IS_SAVED
      this.document.setContent(content)

      // initialize diffSync
      this.diffSync = new DiffSync<Object>(this.document.getContent(), ref)

      // update documents in editor
      this.observers.forEach((update: Callable) => update())
      return
    }

    const message: Message = JSON.parse(event.data as string) as Message
    message.editStack.edits = message.editStack.edits.map((edit) => {
      return new Edit(edit as unknown as EditInit)
    })

    this.diffSync.handleIncomingEdits(message.editStack, this.document, { onAck: this.getOnAck() })
    this.diffSync.pruneOutgoingEdits(message.editStack.remoteRev)

    // update documents in editor
    this.observers.forEach((update: Callable) => update())

    this.websocket.send(
      JSON.stringify({
        editStack: this.diffSync.documentChange(this.document),
        ref: this.diffSync.getRef(),
      }),
    )
  }
}

/**
 * Factory object that creates a {@link DiffSyncClient} given some options
 */
export const DiffSyncClientFactory = {
  create: (options: DiffSyncClientOptions): DiffSyncClient<Object> => new DiffSyncClientImpl(options),
}

/**
 * Diff sync client interface definition.
 * It encapsulates the handling of the differential synchronization
 * algorithm logic from the client perspective.
 * The client is responsible for notifying the diff sync client when changes are happening
 * to the document. Additionally the client can supply an optional callable function to
 * react to incoming changes on the socket
 */
export interface DiffSyncClient<T> {
  /**
   * Handles client changes via diff sync
   *
   * @param content the changed content
   */
  onChanges(content: Object): void

  /**
   * Handles a case when opening the websocket connection to this CO is Forbidden
   */
  onForbidden(callback: Callable): void

  /**
   * Get the content of the document
   */
  getContent(): T

  /**
   * Get the content of the shadow document
   */
  getShadowContent(): T

  /**
   * Get the content of the backup document
   */
  getBackupContent(): T

  /**
   * Get the shadow local revision
   */
  getShadowLocalRev(): number

  /**
   * Get the shadow remote revision
   */
  getShadowRemoteRev(): number

  /**
   * Get the backup local revision
   */
  getBackupLocalRev(): number

  /**
   * Get the edit stack for this diff sync
   */
  getEditStack(): EditStack

  /**
   * Add an update runnable
   *
   * @param update an update runnable to run on incoming changes from the server
   */
  registerObserver(update: Callable): void

  /**
   * Remove an update runnable from the list of registered observers
   *
   * @param update the update runnable to remove
   */
  unRegisterObserver(update: Callable): void

  /**
   * Closes the diff sync connection
   */
  close(): void

  /**
   * Allows to set the onAck callback
   * @param onAck a callable to run when an ack is received from the server
   */
  setOnAck(onAck: Callable): void

  /**
   * Allows to get the onAck callback
   */
  getOnAck(): Callable

  /**
   * Client says if the doc has saved or not
   */
  getSavedStatus(): SavedStatus

  /**
   * Update the saved status to be unsaved
   */
  setUnsaved(): void
}

export interface DiffSyncClientOptions {
  /**
   * The full websocket url
   */
  url: string
  /**
   * The user authentication token
   */
  token: string
  /**
   * The client document initial content
   */
  documentContent: Object
  /**
   * The update function to run on incoming messages received on the websocket
   */
  updateRunnable?: Callable
  /**
   * A method to call when diff sync should be terminated
   */
  destructor?: Callable
  /**
   * A method that returns a fresh new token
   */
  getToken?: () => Promise<string | undefined>
}
